@import "./vars.scss";

.container-fluid {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  & .title {
    width: 100%;
    text-align: center;
    margin: 0;
    height: fit-content;
    padding: 12vh 0 0 0;
    & span {
      margin: auto;
      color: $blue_dark;
      width: 30vw;
      font-size: 1.5vw;
      letter-spacing: $letter_spacing;
      -webkit-user-select: none; /* Safari */
      -ms-user-select: none; /* IE 10 and IE 11 */
      user-select: none;
    }
  }
  & .delivery-row,
  & .office-row,
  & .asset-row,
  & .shared-row,
  & .mobile-row {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 0;
    height: 48vh;
    position: relative;
    & span {
      -webkit-user-select: none; /* Safari */
      -ms-user-select: none; /* IE 10 and IE 11 */
      user-select: none;
      margin: auto;
      font-size: 7.5vw;
      width: fit-content;
      height: fit-content;
      color: $blue_dark;
      letter-spacing: 0.5vw;
      text-shadow: -0.1vw 0.1vw 0px rgba(0, 0, 0, 0.4);
    }
    & picture {
      position: absolute;
      z-index: -1;
      pointer-events: none;
      display: flex;
      justify-content: center;
      -webkit-user-select: none; /* Safari */
      -ms-user-select: none; /* IE 10 and IE 11 */
      user-select: none;
      & img {
        width: 65vw;
      }
    }
  }
  & .mobile-row {
    justify-content: center;
    margin-top: 8vh;
    & span {
      margin: 0 !important;
    }
    & picture {
      -webkit-user-select: none; /* Safari */
      -ms-user-select: none; /* IE 10 and IE 11 */
      user-select: none;
      & img {
        width: 100%;
      }
    }
    & .qr-code-div {
      margin-left: 20vw;
      width: fit-content;
      display: flex;
      & .qr-1 {
        justify-content: center;
        width: fit-content;
        text-align: center;
        margin-right: 5vw;
        & svg {
          margin: auto !important;
          & path {
          }
        }
        & span {
          letter-spacing: 0 !important;
          text-shadow: none !important;
          display: block;
          margin-top: 4vh !important;
          font-size: 2vw;
          background-color: rgba($color: white, $alpha: 0.7);
          border-radius: 0.3vw;
          padding: 0 0.5vw;
        }
      }
      & .qr-2 {
        justify-content: center;
        width: fit-content;
        text-align: center;
        & svg {
          margin: auto !important;
        }
        & span {
          letter-spacing: 0 !important;
          text-shadow: none !important;
          display: block;
          margin-top: 4vh !important;
          font-size: 2vw;
          background-color: rgba($color: white, $alpha: 0.7);
          border-radius: 0.3vw;
          padding: 0 0.5vw;
        }
      }
    }
  }
  & .button-row {
    margin: 0;
    height: fit-content;
    & button {
      width: 10vw;
      height: 3vw;
      font-size: 1vw;
      letter-spacing: $letter_spacing;
      color: $white;
      border: none;
      font-weight: 600;
      border-radius: 0.3vw;
      -webkit-user-select: none; /* Safari */
      -ms-user-select: none; /* IE 10 and IE 11 */
      user-select: none;
      outline: none;
      margin: auto;
      &.btn-del {
        background-color: $orange;
      }
      &.btn-shar {
        background-color: $shared;
      }
      &.btn-off {
        background-color: $blue;
      }
      &.btn-ass {
        background-color: $green;
        color: $blue_dark;
      }
    }
  }
}
.tab-pane.active {
  animation-name: fadeIn;
  animation-duration: 500ms;
  animation-timing-function: linear;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}
