$blue: #3fc1f0;
$orange: #ff9200;
$purple: #6c63ff;
$green: #00ffab;
$blue_dark: #00404f;
$light_grey: #fdfdfd;
$white: #ffffff;
$letter_spacing: 0.1vw;
$shared: #ad32c6;

$uk_: #012169;

:export {
    purple: $purple;
    blue_dark: $blue_dark;
}
