@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap");
:export {
  purple: #6c63ff;
  blue_dark: #00404f;
}

:export {
  purple: #6c63ff;
  blue_dark: #00404f;
}

.dragable-div {
  background-color: transparent;
  position: relative;
  width: 100vw;
  height: 100vh;
}
.dragable-div .footer-row {
  margin: 0 !important;
  height: fit-content;
  width: 50%;
  position: fixed;
  bottom: 5vh;
  left: 50%;
  transform: translate(-50%, 0);
}
.dragable-div .footer-row .col {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: fit-content;
}
.dragable-div .footer-row .col svg {
  width: 3vw;
  height: 3vw;
}
.dragable-div .footer-row .col svg path {
  stroke: #00404f;
}
.dragable-div .footer-row .col svg.shared {
  width: 2.6vw;
  height: 2.6vw;
}
.dragable-div .footer-row .col svg.asset {
  width: 3.5vw;
  height: 3.3vw;
}
.dragable-div .footer-row .col:hover svg.lock * {
  stroke: #3fc1f0;
}
.dragable-div .footer-row .col:hover svg.shared {
  border-radius: 0.4vw;
}
.dragable-div .footer-row .col:hover svg.shared * {
  stroke: #ad32c6;
}
.dragable-div .footer-row .col:hover svg.delivery * {
  stroke: #ff9200;
}
.dragable-div .footer-row .col:hover svg.asset * {
  stroke: #00ffab;
}
.dragable-div .footer-row .col:hover svg.mobile * {
  stroke: #6c63ff;
}
.dragable-div .footer-row .col.active-menu-item {
  animation-name: fadeIn;
  animation-duration: 500ms;
  animation-timing-function: linear;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.dragable-div .footer-row .col.active-menu-item svg.lock * {
  stroke: #3fc1f0;
}
.dragable-div .footer-row .col.active-menu-item svg.shared * {
  stroke: #ad32c6;
}
.dragable-div .footer-row .col.active-menu-item svg.delivery * {
  stroke: #ff9200;
}
.dragable-div .footer-row .col.active-menu-item svg.asset * {
  stroke: #00ffab;
}
.dragable-div .footer-row .col.active-menu-item svg.mobile * {
  stroke: #6c63ff;
}
.dragable-div .change-lang-row {
  margin: 0;
  position: absolute;
  right: 1vw;
  top: 1vw;
}
.dragable-div .change-lang-row button {
  border: none;
  outline: none;
  color: white;
  width: 3vw;
  height: 3vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5vw;
  background-color: white;
  border-radius: 0.4vw;
}
.dragable-div .change-lang-row button:first-of-type {
  margin-right: 1vw;
}
.dragable-div .change-lang-row button.pt {
  border: 0.2vw solid #012169;
  color: #012169;
}
.dragable-div .change-lang-row button.pt.active {
  color: white !important;
  background-color: #012169;
  border: none;
}
.dragable-div .change-lang-row button.en {
  border: 0.2vw solid #012169;
  color: #012169;
}
.dragable-div .change-lang-row button.en.active {
  color: white !important;
  background-color: #012169;
  border: none;
}

.loading {
  position: absolute;
  left: calc(50% - 35px);
  top: 55%;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 3px solid transparent;
}
.loading.component-loader {
  margin-top: -60px;
}
.loading .effect-1,
.loading .effect-2 {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-left: 3px solid #7961f9;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.loading .effect-1 {
  animation: rotate 1s ease infinite;
}
.loading .effect-2 {
  animation: rotateOpacity 1s ease infinite 0.1s;
}
.loading .effect-3 {
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-left: 3px solid #7961f9;
  -webkit-animation: rotateOpacity 1s ease infinite 0.2s;
  animation: rotateOpacity 1s ease infinite 0.2s;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.loading .loading .effects {
  transition: all 0.3s ease;
}

.fallback-logo {
  position: absolute;
  left: calc(50% - 75px);
  top: 40%;
}

.fallback-spinner {
  border: 2px solid white;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@keyframes rotateOpacity {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 0.1;
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
    opacity: 1;
  }
}
:export {
  purple: #6c63ff;
  blue_dark: #00404f;
}

.container-fluid {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
.container-fluid .title {
  width: 100%;
  text-align: center;
  margin: 0;
  height: fit-content;
  padding: 12vh 0 0 0;
}
.container-fluid .title span {
  margin: auto;
  color: #00404f;
  width: 30vw;
  font-size: 1.5vw;
  letter-spacing: 0.1vw;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
}
.container-fluid .delivery-row, .container-fluid .office-row, .container-fluid .asset-row, .container-fluid .shared-row, .container-fluid .mobile-row {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0;
  height: 48vh;
  position: relative;
}
.container-fluid .delivery-row span, .container-fluid .office-row span, .container-fluid .asset-row span, .container-fluid .shared-row span, .container-fluid .mobile-row span {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  margin: auto;
  font-size: 7.5vw;
  width: fit-content;
  height: fit-content;
  color: #00404f;
  letter-spacing: 0.5vw;
  text-shadow: -0.1vw 0.1vw 0px rgba(0, 0, 0, 0.4);
}
.container-fluid .delivery-row picture, .container-fluid .office-row picture, .container-fluid .asset-row picture, .container-fluid .shared-row picture, .container-fluid .mobile-row picture {
  position: absolute;
  z-index: -1;
  pointer-events: none;
  display: flex;
  justify-content: center;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
}
.container-fluid .delivery-row picture img, .container-fluid .office-row picture img, .container-fluid .asset-row picture img, .container-fluid .shared-row picture img, .container-fluid .mobile-row picture img {
  width: 65vw;
}
.container-fluid .mobile-row {
  justify-content: center;
  margin-top: 8vh;
}
.container-fluid .mobile-row span {
  margin: 0 !important;
}
.container-fluid .mobile-row picture {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
}
.container-fluid .mobile-row picture img {
  width: 100%;
}
.container-fluid .mobile-row .qr-code-div {
  margin-left: 20vw;
  width: fit-content;
  display: flex;
}
.container-fluid .mobile-row .qr-code-div .qr-1 {
  justify-content: center;
  width: fit-content;
  text-align: center;
  margin-right: 5vw;
}
.container-fluid .mobile-row .qr-code-div .qr-1 svg {
  margin: auto !important;
}
.container-fluid .mobile-row .qr-code-div .qr-1 span {
  letter-spacing: 0 !important;
  text-shadow: none !important;
  display: block;
  margin-top: 4vh !important;
  font-size: 2vw;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 0.3vw;
  padding: 0 0.5vw;
}
.container-fluid .mobile-row .qr-code-div .qr-2 {
  justify-content: center;
  width: fit-content;
  text-align: center;
}
.container-fluid .mobile-row .qr-code-div .qr-2 svg {
  margin: auto !important;
}
.container-fluid .mobile-row .qr-code-div .qr-2 span {
  letter-spacing: 0 !important;
  text-shadow: none !important;
  display: block;
  margin-top: 4vh !important;
  font-size: 2vw;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 0.3vw;
  padding: 0 0.5vw;
}
.container-fluid .button-row {
  margin: 0;
  height: fit-content;
}
.container-fluid .button-row button {
  width: 10vw;
  height: 3vw;
  font-size: 1vw;
  letter-spacing: 0.1vw;
  color: #ffffff;
  border: none;
  font-weight: 600;
  border-radius: 0.3vw;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  outline: none;
  margin: auto;
}
.container-fluid .button-row button.btn-del {
  background-color: #ff9200;
}
.container-fluid .button-row button.btn-shar {
  background-color: #ad32c6;
}
.container-fluid .button-row button.btn-off {
  background-color: #3fc1f0;
}
.container-fluid .button-row button.btn-ass {
  background-color: #00ffab;
  color: #00404f;
}

.tab-pane.active {
  animation-name: fadeIn;
  animation-duration: 500ms;
  animation-timing-function: linear;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media only screen and (max-width: 600px) {
  html {
    max-height: 100vh !important;
    min-height: 100vh !important;
  }

  body {
    position: absolute;
    margin: 0 !important;
    padding: 0 !important;
    min-height: 100vh !important;
    max-height: 100vh !important;
  }

  #root {
    position: absolute !important;
    margin: 0 !important;
    padding: 0 !important;
    min-height: 100vh !important;
    max-height: 100vh !important;
  }

  .container-fluid .title {
    margin-top: 10vh !important;
    padding: 0 !important;
  }
  .container-fluid .title span {
    width: 65vw !important;
    font-size: 3vw !important;
  }
  .container-fluid .delivery-row, .container-fluid .shared-row, .container-fluid .office-row, .container-fluid .asset-row, .container-fluid .mobile-row {
    height: 50vh !important;
  }
  .container-fluid .delivery-row span, .container-fluid .shared-row span, .container-fluid .office-row span, .container-fluid .asset-row span, .container-fluid .mobile-row span {
    font-size: 15vw !important;
  }
  .container-fluid .delivery-row picture img, .container-fluid .shared-row picture img, .container-fluid .office-row picture img, .container-fluid .asset-row picture img, .container-fluid .mobile-row picture img {
    width: 100vw !important;
  }
  .container-fluid .mobile-row {
    width: 100vw !important;
  }
  .container-fluid .mobile-row .qr-code-div {
    width: 100% !important;
    justify-content: center;
    margin: 0 0vw 5vh 0vw !important;
  }
  .container-fluid .mobile-row .qr-code-div .qr-1 span, .container-fluid .mobile-row .qr-code-div .qr-2 span {
    font-size: 4vw !important;
  }
  .container-fluid .mobile-row .qr-code-div .qr-1 {
    margin-right: 15vw !important;
  }
  .container-fluid .button-row button {
    width: 40vw !important;
    height: 12vw !important;
    font-size: 5vw !important;
    border-radius: 1vw !important;
  }

  .footer-row {
    width: 80% !important;
  }
  .footer-row .col svg {
    width: 8vw !important;
    height: 8vw !important;
  }

  .dragable-div {
    position: absolute !important;
    min-height: 100vh !important;
    max-height: 100vh !important;
  }
  .dragable-div .change-lang-row {
    position: absolute !important;
    right: 2vw !important;
    top: 1vh !important;
  }
  .dragable-div .change-lang-row button {
    width: 5vw;
    height: 7vw;
    font-size: 2.5vw;
  }

  .Toastify__toast-container {
    width: 60vw !important;
  }
}
.Toastify__toast-container .Toastify__toast {
  box-shadow: none !important;
  border-radius: 0.4vw;
}
.Toastify__toast-container .Toastify__toast.Toastify__toast--success {
  background: white !important;
  border: 2px solid #00404f;
}
.Toastify__toast-container .Toastify__toast.Toastify__toast--error {
  background: white !important;
  border: 2px solid red;
}
.Toastify__toast-container .Toastify__toast .Toastify__toast-body {
  color: #00404f !important;
}
.Toastify__toast-container .Toastify__toast .Toastify__close-button {
  color: #00404f;
}
.Toastify__toast-container .Toastify__toast .Toastify__progress-bar {
  background-color: #00404f;
}

body {
  font-family: "Open Sans", sans-serif;
  font-size: 0.75rem;
  overflow: hidden;
  max-height: 100vh;
  max-width: 100vw;
}

#root {
  padding: 0;
  margin: 0;
  max-height: 100vh;
  min-height: 100vh;
  position: relative;
}

p {
  font-size: 0.9rem;
  font-weight: 400;
  color: #626262;
  user-select: none;
}

h4 {
  font-size: 1.32rem;
  font-weight: 500;
  line-height: 1.2;
  color: #2c2c2c;
}
